<template>
    <v-dialog
        v-if="isVisible"
        v-model="isVisible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="600"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text
                class="wrapperFormModal"
            >
                <v-card flat>
                    <v-card-text>

                        <v-row no-gutters>

                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label f-label-more-rows">
                                    {{$t("Текст_комментария")}}
                                </label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">
                                <v-textarea 
                                    v-model="comment"
                                    rows="3"
                                    no-resize
                                    hide-details
                                    required
                                    outlined
                                    dense
                                />
                            </v-col>

                        </v-row>

                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="isValid"
                >
                    {{ $t("Добавить") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "cscCommentDlg",
    data () {
        return {
            title: "Комментарий",
        }
    },
    computed: {
        ...mapGetters('dialogs/cscComment', ['isVisible', 'isValid']),
        
        comment: {
            get: function() {
                return this.$store.getters['dialogs/cscComment/comment'];
            },
            set: function(v) {
                this.$store.commit('dialogs/cscComment/SET_COMMENT', v); 
            }
        },   
    },
    methods: {
        ...mapActions('dialogs/cscComment', ['ok', 'cancel']),
    }
}
</script>